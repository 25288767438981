<template>
  <div>
    <div class="breadcrumb">
      <h1>Parametros</h1>
    </div>
    <div class="row mb-4">
      <div class="col-sm-12 mb-4">
        <div class="card text-left">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-10">
                <button
                  class="btn border border-light col-sm-2 mr-3"
                  :class="isCrear ? 'th-custom-color text-dark': 'btn-white'"
                  @click="componentShow = 'Crear'">
                  Estructura Consultorio
                </button>
                <button
                  class="btn border border-light col-sm-2 mr-3"
                  :class="isClasificacionCie ? 'th-custom-color text-dark': 'btn-white'"
                  @click="componentShow = 'ClasificacionCie'"
                >
                  <b>Clasificacion CIE</b>
                </button>
                <button
                  class="btn border border-light col-sm-2 mr-3"
                  :class="isCategoriaLinae ? 'th-custom-color text-dark': 'btn-white'"
                  @click="componentShow = 'CategoriaLinae'"
                >
                  <b>Categoria LINAE</b>
                </button>
                <button
                  class="btn bt-white border border-light col-sm-2 mr-3"
                >
                  <b>Productos LINAE</b>
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-9" v-if="!isClasificacionCie && !isCategoriaLinae">
                <div class="row">
                  <button
                    class="btn border border-light col-sm-2 mx-3"
                    :class="isCrear ? 'btn-light text-dark': 'btn-white'"
                    @click="componentShow = 'Crear'"
                  >
                    <b>Crear</b>
                  </button>
                  <button
                    class="btn border border-light col-sm-2 mr-3"
                    :class="isAsignar ? 'btn-light text-dark': 'btn-white'"
                    @click="componentShow = 'Asignar'"
                  >
                    <b>Asignar</b>
                  </button>
                </div>
              </div>
            </div>
            <Crear v-if="isCrear"/>
            <Asignar v-if="isAsignar"/>
            <TheTabClasificacionCie v-if="isClasificacionCie"/>
            <TheTablCategoriaLinae v-if="isCategoriaLinae"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Crear from '../components/TheCrear.vue';
import Asignar from '../components/TheAsignar.vue';
import TheTablCategoriaLinae from '../components/TheTabCategoriaLinae.vue';
import TheTabClasificacionCie from '../components/TheTabClasificacionCie.vue';

export default {
  name: 'index',
  components: {
    Crear,
    Asignar,
    TheTablCategoriaLinae,
    TheTabClasificacionCie,
  },
  data() {
    return {
      componentShow: 'Crear',
    };
  },
  computed: {
    isCrear() {
      return (this.componentShow === 'Crear');
    },
    isAsignar() {
      return (this.componentShow === 'Asignar');
    },
    isClasificacionCie() {
      return (this.componentShow === 'ClasificacionCie');
    },
    isCategoriaLinae() {
      return (this.componentShow === 'CategoriaLinae');
    },
  },
};
</script>

<style scoped>
</style>
