<template>
  <div>
    <div class="breadcrumb">
        <h1>Nueva Clasificacion</h1>
    </div>
    <div class="separator-breadcrumb border-top"></div>
    <div class="row mb-4">
      <div class="col-sm-12 mb-4">
        <div class="card text-left">
          <form autocomplete="off">
            <div class="card-header">
              <div class="row">
                  <div class="col-sm-12">
                      <button class="btn th-custom-color text-dark"
                      type="button"
                      :disabled="buttonDisabled"
                      @click="guardarClasificacion">Guardar</button>
                      <button type="button" class="btn btn-secondary m-1"
                        @click="componentShow = 'TheIndexClasificacionCie'">
                          Cancelar
                      </button>
                  </div>
              </div>
            </div>
            <div class="card-body">
              <div class="form-group row">
                <label for="nombre_clasificacion"
                class="col-sm-2 col-form-label text-right">
                Codigo Clasificacion:
                <span
                    class="text-danger">*
                </span>
                </label>
                <div class="col-sm-2">
                    <input type="text" class="form-control"
                    v-model="codigoClasificacion"
                    placeholder="Ingrese el codigo" required>
                </div>
                <label for="descripcion_grupo"
                class="col-sm-2 col-form-label text-right">
                  Nombre Clasificacion:
                  <span
                    class="text-danger">*
                  </span>
                </label>
                <div class="col-sm-3">
                  <input type="text" class="form-control"
                  v-model="nombreClasificacion"
                  placeholder="Ingrese el nombre de la clasificacion" required>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-12">
                  <h4>SubClasificacion</h4>
                </div>
              </div>
              <div class="form-group row">
                <label for="nombre_sub_clasificacion"
                class="col-sm-2 col-form-label text-right">
                Codigo Sub Clasificacion:
                <span
                    class="text-danger">*
                </span>
                </label>
                <div class="col-sm-2">
                    <input type="text" class="form-control"
                    v-model="codigoSubClasificacion"
                    placeholder="Ingrese el codigo" required>
                </div>
                <label for="descripcion_sub_clasificacion"
                class="col-sm-2 col-form-label text-right">
                  Nombre Sub Clasificacion:
                  <span
                    class="text-danger">*
                  </span>
                </label>
                <div class="col-sm-3">
                  <input type="text" class="form-control"
                  v-model="nombreSubClasificacion"
                  placeholder="Ingrese el nombre de la sub clasificacion" required>
                </div>
                <div class="col-sm-3">
                  <button class="btn th-custom-color text-dark"
                  type="button"
                  @click="addSubClasificacion()"
                  >Agregar</button>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div style="display: flex; width: 100%; text-align:center">
                    <table class="table table-bordered table-hover" width="100%">
                      <thead>
                        <tr>
                          <th width="3%"></th>
                          <th width="3%"></th>
                          <th width="10%" class="text-center">ID</th>
                          <th width="20%" class="text-center">Codigo</th>
                          <th width="45%">Nombre</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(subClasificion, index) in listaSubClasificacions" :key="index">
                          <td class="text-center">
                            <i class="nav-icon i-Pen-5 font-weight-bold text-success"
                            style="cursor: pointer"
                            @click="edit(subClasificion)"
                            ></i>
                          </td>
                          <td class="text-center">
                            <a class="delete text-danger"
                            @click="eliminar(index)">
                                <i class="nav-icon i-Close-Window font-weight-bold"
                                style="cursor: pointer"
                                >
                                </i>
                            </a>
                          </td>
                          <td> {{ index + 1 }} </td>
                          <td>
                            <input type="text"
                            v-model="subClasificion.codigo"
                            :readonly="readInputs[subClasificion.numero - 1].value"
                            class="form-control">
                          </td>
                          <td>
                            <input type="text" v-model="subClasificion.nombre"
                            :readonly="readInputs[subClasificion.numero - 1].value"
                            class="form-control">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { util } from '@/plugins/util';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';

export default {
  name: 'TheNuevoClasificacionCie',
  data() {
    return {
      buttonDisabled: false,
    };
  },
  methods: {
    addSubClasificacion() {
      if (this.validarAddSubClasificacion()) {
        this.listaSubClasificacions.push({
          numero: this.readInputs.length + 1,
          nombre: this.nombreSubClasificacion,
          codigo: this.codigoSubClasificacion,
        });
        this.readInputs.push({ value: true });
        this.limpiarDataSubClasificacion();
      }
    },
    limpiarDataSubClasificacion() {
      this.codigoSubClasificacion = null;
      this.nombreSubClasificacion = null;
    },
    validarAddSubClasificacion() {
      let valido = true;
      if (!this.nombreSubClasificacion) {
        valido = false;
        util.showNotify('El nombre de la sub clasificacion es requerido', 'warn');
      }
      if (!this.codigoSubClasificacion) {
        valido = false;
        util.showNotify('El codigo de la sub clasificacion es requerido', 'warn');
      }
      const codigo = this.listaSubClasificacions.find((el) => (
        el.codigo === this.codigoSubClasificacion));
      if (codigo) {
        valido = false;
        util.showNotify('El codigo de la sub clasificacion ya existe en el detalle', 'warn');
      }
      return valido;
    },
    async guardarClasificacion() {
      this.buttonDisabled = true;
      if (this.validar()) {
        const STORE_OK = await this.callStoreApi();
        if (STORE_OK) {
          this.callIndexApi();
        }
      }
      this.buttonDisabled = false;
    },
    validar() {
      let valido = true;
      if (!this.codigoClasificacion) {
        valido = false;
        util.showNotify('El codigo de la clasificacion es requerido', 'warn');
      }
      if (!this.nombreClasificacion) {
        valido = false;
        util.showNotify('El nombre de la clasificacion es requerido', 'warn');
      }
      if (this.listaSubClasificacions.length === 0) {
        valido = false;
        util.showNotify('Debe agregar al menos una sub clasificacion', 'warn');
      }
      return valido;
    },
    eliminar(index) {
      this.listaSubClasificacions.splice(index, 1);
    },
    edit(row) {
      this.readInputs[row.numero - 1].value = false;
    },
    ...mapActions('clasificacionApis', [
      'callIndexApi',
      'callStoreApi',
    ]),
    ...mapActions('formularioClasificacion', [
      'resetFormularioClasificacionStore',
    ]),
  },
  beforeDestroy() {
    this.resetFormularioClasificacionStore();
  },
  computed: {
    ...mapFields('formularioClasificacion', [
      'componentShow',
      'nombreClasificacion',
      'codigoClasificacion',
      'listaSubClasificacions',
      'codigoSubClasificacion',
      'nombreSubClasificacion',
      'readInputs',
    ]),
  },
};
</script>
